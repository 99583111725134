/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { all_websites } from "../util/const"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export const pageQuery = graphql`
  query JakZarezerwowacQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
    }
  }
`

const JakZarezerwowac = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark
  const parking = all_websites.find(
    ({ slug }) => slug === process.env.GATSBY_PARKING_SLUG,
  )
  const cityName = `${(parking || "").name?.replace("Parking ", "")}`
  return (
    <div>
      <Seo
        title={`${parking.name} - Jak zarezerwować parking przy lotnisku? ${parking.url}`}
        description={`${parking.name} - Jak zarezerwować parking przy lotnisku ${cityName} w 4 prostych krokach?`}
      />
      <Layout className="contact-page" sx={contactStyles.JakZarezerwowac}>
        <div className="wrapper">
          <h1>{`${parking.name} - Jak zarezerwować parking przy lotnisku? ${cityName}`}</h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="">
            <p>
              <strong>
                Oto instrukcja, jak zarezerwować parking przy lotnisku{" "}
                {cityName}
              </strong>
            </p>
            <ol>
              <li>
                <strong>Wybierz datę i godzinę przyjazdu:</strong> Rozpocznij
                proces rezerwacji, wybierając dokładną datę oraz godzinę, kiedy
                planujesz przyjechać na parking.
              </li>
            </ol>
            <p>
              <StaticImage
                src="../../static/assets/blog/data_przyjazdu.jpeg"
                alt="data przyjazdu na lotnisko"
                placeholder="blurred"
              />
            </p>
            <ol start="2">
              <li>
                <strong>Określ datę i godzinę wyjazdu:</strong> Podaj również,
                kiedy planujesz wrócić i odebrać swój samochód.
              </li>
            </ol>
            <p>
              <StaticImage
                src="../../static/assets/blog/godzina_wyjazdu.jpeg"
                alt="godzina wyjazdy na lotnisko"
                placeholder="blurred"
              />
            </p>
            <ol start="3">
              <li>
                <strong>Kliknij “Szukaj”:</strong> Po wprowadzeniu wszystkich
                niezbędnych informacji, kliknij przycisk “Szukaj”, aby zobaczyć
                dostępne opcje parkingowe.
              </li>
            </ol>
            <p>
              <StaticImage
                src="../../static/assets/blog/przycisk_szukaj.jpeg"
                alt="przycisk szukaj"
                placeholder="blurred"
              />
            </p>
            <p>
              <strong>Przeglądaj dostępne parkingi:</strong> Zostaniesz
              przekierowany do listy parkingów dostępnych w wybranym przez
              Ciebie terminie. Przejrzyj oferty i wybierz tę, która najbardziej
              Ci odpowiada.
            </p>
            <ol start="4">
              <li>
                <strong>Kliknij “Zarezerwuj”:</strong> Gdy już zdecydujesz się
                na konkretny parking, kliknij przycisk “Zarezerwuj”, aby przejść
                do finalizacji rezerwacji.
              </li>
            </ol>
            <p>
              <StaticImage
                src="../../static/assets/blog/przycisk_rezerwuj.jpeg"
                alt="Przycisk rezerwuj"
                placeholder="blurred"
              />
            </p>
            <p>
              To wszystko! Po zakończeniu tych kroków Twój parking przy lotnisku
              będzie zarezerwowany. Zaplanuj podróż z wyprzedzeniem i ciesz się
              bezproblemowym parkowaniem!
            </p>
            <p>
              Sprawdź <a href="/#search">listę parkingów</a> i zarezerwuj
              parking przy lotnisku {cityName} już dziś!
            </p>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default JakZarezerwowac

const contactStyles = {
  JakZarezerwowac: {
    input: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
    textarea: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
  },
}
